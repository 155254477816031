import { defineComponent as o, openBlock as n, createBlock as r, resolveDynamicComponent as a, withCtx as s, renderSlot as i } from "vue";
import { _ as c } from "./chunks/_plugin-vue_export-helper.CHgC5LLL.js";
import './css/Container.css';const p = /* @__PURE__ */ o({
  __name: "Container",
  props: {
    /**
     * The HTML tag to render.
     *
     * @default 'div'
     */
    is: {
      type: String,
      default: "div"
    }
  },
  setup(e) {
    return (t, _) => (n(), r(a(e.is), { class: "container" }, {
      default: s(() => [
        i(t.$slots, "default", {}, void 0, !0)
      ]),
      _: 3
    }));
  }
}), f = /* @__PURE__ */ c(p, [["__scopeId", "data-v-bb3149e5"]]);
export {
  f as default
};
